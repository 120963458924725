import * as React from "react";
import { FileOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Button, Modal } from "antd5";
import { ButtonType } from "antd5/es/button/buttonHelpers";

import { ButtonLink, ButtonLinkProps, TextLink } from "components/actions/Links";
import { useDialogManager } from "lib/providers/DialogManager";
import * as tracking from "lib/tracking";

// it's enough to check whether the source docs url `includes` one of these
const BRAVO_SOLUTIONS_PORTALS = [
  // this covers the majority
  "bravosolution.",
  // these are the less obvious ones, because their connection to bravo isn't explicit
  "contracts.mod.uk",
  "capitalesourcing.com",
  "nhssourcing.co.uk",
  "tenders.bris.ac.uk",
];
const BRAVO_SOLUTIONS_PUBLIC_OPPORTUNITIES_PATH = "/esop/guest/go/public/opportunity/current";

const UK_INTEND_SELL_TO_CONTRACTS_PATTERN =
  /https?:\/\/sell2\.in-tend\.co\.uk\/[a-zA-Z0-9-]+\/contracts/i;

type Props = {
  url: string;
  linkType?: "text" | "button";
  buttonType?: ButtonType;
  showIcon?: boolean;
};

function SrcDocsLink({
  url: tenderUrl,
  linkType = "button",
  buttonType = "primary",
  showIcon,
}: Props): JSX.Element {
  const dialogManager = useDialogManager();
  const props: ButtonLinkProps = {
    to: tenderUrl,
    targetType: "external",
    // This is a little confusing but the icon file shows an ant icon and the disabling
    // of the showIcon param hides the external icon from ButtonLink
    showIcon: showIcon,
    eventName: tracking.EventNames.navToSourceDocs,
  };

  const isBravoPortal = BRAVO_SOLUTIONS_PORTALS.some((portal) => tenderUrl.includes(portal));
  const isBlpdPortal = tenderUrl.includes("https://www.blpd.gov.uk");
  const isUkIntendSell2ContractsPortal = UK_INTEND_SELL_TO_CONTRACTS_PATTERN.test(tenderUrl);

  if (isBravoPortal) {
    const urlOrigin = new URL(tenderUrl).origin;

    props.onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();
      const startUrl = urlOrigin + BRAVO_SOLUTIONS_PUBLIC_OPPORTUNITIES_PATH;
      const newWindow = window.open(startUrl, "_blank");

      setTimeout(() => {
        if (newWindow) {
          newWindow.location.href = tenderUrl;
        } else {
          Sentry.captureException(
            `cannot open source docs for ${tenderUrl} - newWindow not present`,
          );
        }
      }, 1500);
    };
  } else if (isBlpdPortal) {
    props.onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();
      void dialogManager.openDialog(BlpdPortalWarning, {});
    };
  } else if (isUkIntendSell2ContractsPortal) {
    props.onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();
      void dialogManager.openDialog(UkIntendSell2PortalWarning, { url: tenderUrl });
    };
  }

  if (linkType === "text") {
    return (
      <TextLink {...props} showIcon={showIcon}>
        {tenderUrl}
      </TextLink>
    );
  }

  return (
    <ButtonLink {...props} type={buttonType} icon={showIcon ? "" : <FileOutlined />}>
      See source documents
    </ButtonLink>
  );
}

type BlpdPortalWarningProps = {
  isOpen: boolean;
  onClose: () => void;
};

function BlpdPortalWarning({ isOpen, onClose }: BlpdPortalWarningProps): JSX.Element {
  return (
    <Modal
      open={isOpen}
      onOk={onClose}
      title="The old BLPD portal has stopped working"
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <p>Blue light commercial has replaced the old BLPD portal with a new procurement hub.</p>
      <p>
        Any notice published before it was taken offline will remain available to view on Stotles.
      </p>
      <p>
        To access source documents for notices published on the old BLPD portal, you might find some
        of them in the new procurement hub's search page.
      </p>
      <p>
        To find a specific notice, we recommend searching for the title of the notice in the
        "Contract title" OR "Keyword" filter found on the portals search page.
      </p>
      <p>
        Go to new{" "}
        <TextLink to="https://sell2.in-tend.co.uk/blpd/home" targetType="new-tab">
          BLPD procurement hub
        </TextLink>
      </p>
    </Modal>
  );
}

type UkIntendSell2PortalWarningProps = {
  isOpen: boolean;
  onClose: () => void;
  url: string;
};

function UkIntendSell2PortalWarning({
  isOpen,
  onClose,
  url,
}: UkIntendSell2PortalWarningProps): JSX.Element {
  return (
    <Modal
      open={isOpen}
      onOk={onClose}
      title="We can't take you directly to this notice"
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <p>
        Due to the structure of this website, you will need to navigate to this specific notice from
        the search page of this portal yourself.
      </p>
      <p>
        To find this specific notice, we recommend searching for the title of the notice in the
        "Contract Title" filter found on the portals search page.
      </p>
      <p>
        <TextLink to={url} targetType="new-tab">
          Go to source
        </TextLink>
      </p>
    </Modal>
  );
}

export default SrcDocsLink;
