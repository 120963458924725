import React, { useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, Tag } from "antd5";

import { useDebouncedValue } from "lib/debounce";
import { useBuyers } from "lib/hooks/api/buyer/useBuyers";
import { useSignalSettingCountryCodes } from "lib/hooks/api/teams/useSignalSettings";
import { Select, SelectProps } from "./Inputs";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  userBuyerIdOptions?: boolean;
};
export function BuyerSelect<T extends FieldValues>({
  userBuyerIdOptions = false,
  ...props
}: FieldProps<T>) {
  const { field } = useController(props);
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  // used to fetch the names when we just have the ids

  const showInitialBuyers = !!field.value && !debouncedText && field.value.length > 0;
  const { data: initialBuyers, isLoading: isLoadingInitial } = useBuyers(
    { guids: field.value },
    {
      enabled: showInitialBuyers,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const userCountryCodes = useSignalSettingCountryCodes();

  const { data, isLoading } = useBuyers(
    {
      sort: "relevance",
      text: debouncedText,
      limit: 20,
      country: userCountryCodes,
    },
    { enabled: !showInitialBuyers && debouncedText.length > 1 },
  );

  let options = data?.results
    ? data.results.map((buyer) => ({
        label: buyer.name,
        value: userBuyerIdOptions ? buyer.id : buyer.guid,
      }))
    : [];

  // we're only going to show the initial buyers if we have no search text and no options
  if (!debouncedText && initialBuyers?.results && options.length === 0) {
    options = initialBuyers.results.map((buyer) => ({
      label: buyer.name,
      value: userBuyerIdOptions ? buyer.id : buyer.guid,
    }));
  }

  const loading =
    (isLoading && !showInitialBuyers && !data) ||
    (isLoadingInitial && showInitialBuyers && !initialBuyers);

  const renderDropdown = (menu: React.ReactElement) => {
    if (loading && !!debouncedText) {
      return <Skeleton style={{ padding: "8px", paddingBottom: "0px" }} active />;
    }

    return menu;
  };

  const dropdownStyle = debouncedText === "" ? { display: "none" } : {};

  return (
    <Select
      {...props}
      selectAll={!!debouncedText}
      loading={loading && !!debouncedText}
      options={options}
      optionFilterProp="label"
      tagRender={(props) => (
        <Tag {...props} style={{ marginRight: 3 }}>
          {/* When value = label, they're both the ID. If we use only loading... 
            then when we refetch results, already correct tags become loading tags */}
          {loading && props.label?.toString() === props.value ? <LoadingOutlined /> : props.label}
        </Tag>
      )}
      dropdownRender={renderDropdown}
      dropdownStyle={dropdownStyle}
      onSearch={(val) => setSearchText(val)}
      showSearch
      filterOption={false}
    />
  );
}
