import React from "react";
import { Drawer } from "antd5";

import NoticePreviewContainer from "components/record_details/NoticePreview";
import { useMarkAsSeen } from "lib/hooks/api/records/useMarkAsSeen";
import { useTracking } from "lib/tracking";
import { usePaywall } from "./Paywall";

import css from "./RecordViewer.module.scss";

type CustomViewerConfig = {
  renderContent: (recordGuid: string) => React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecordViewerEventData = Record<string, any>;

type RecordViewer = {
  viewRecord: (
    recordGuid: string,
    trackingData: RecordViewerEventData,
    customConfig?: CustomViewerConfig,
  ) => void;
  closeRecord: () => void;
  recordGuid: string | undefined;
};

const RecordViewerContext = React.createContext<RecordViewer | undefined>(undefined);

type RecordViewerProviderProps = {
  children: React.ReactNode;
};

export function RecordViewerProvider({ children }: RecordViewerProviderProps): JSX.Element {
  const [recordGuid, setRecordGuid] = React.useState<string>();
  const [eventData, setEventData] = React.useState<RecordViewerEventData | undefined>(undefined);
  const [customConfig, setCustomConfig] = React.useState<CustomViewerConfig>();
  const { mutate: markAsSeen } = useMarkAsSeen();
  const { openPaywallModal } = usePaywall();

  const onClose = React.useCallback(() => {
    const creditLimitReached = sessionStorage.getItem("awardCreditLimitReached");
    if (creditLimitReached) {
      openPaywallModal("AWARDS_LOCKED");
      sessionStorage.removeItem("awardCreditLimitReached");
    }

    setRecordGuid(undefined);
    setEventData(undefined);
    setCustomConfig(undefined);
  }, [openPaywallModal]);

  const context = React.useMemo(
    () => ({
      viewRecord: (
        recordGuid: string,
        trackingData: RecordViewerEventData,
        customConfig?: CustomViewerConfig,
      ) => {
        setRecordGuid(recordGuid);
        setEventData(trackingData);
        setCustomConfig(customConfig);
        markAsSeen({ recordGuid });
      },
      closeRecord: onClose,
      recordGuid: recordGuid,
    }),
    [markAsSeen, recordGuid, onClose],
  );

  return (
    <RecordViewerContext.Provider value={context}>
      {children}
      {recordGuid && (
        <Drawer
          onClose={onClose}
          open={!!recordGuid}
          closeIcon={null}
          styles={{
            body: { padding: 0 },
            content: { backgroundColor: "#FFF" },
          }}
          width={1200}
        >
          <div className={css.recordViewer}>
            {customConfig ? (
              customConfig.renderContent(recordGuid)
            ) : (
              <NoticePreviewContainer guid={recordGuid} eventData={eventData} />
            )}
          </div>
        </Drawer>
      )}
    </RecordViewerContext.Provider>
  );
}

export function useRecordViewer(customConfig?: CustomViewerConfig) {
  const { trackingData } = useTracking();
  const context = React.useContext(RecordViewerContext);
  if (context === undefined) {
    throw new Error("useRecordViewer must be used within a RecordViewerProvider");
  }
  const viewRecord = React.useCallback(
    (recordGuid: string, eventData: { "Context source": string }) => {
      return context.viewRecord(recordGuid, { ...trackingData, ...eventData }, customConfig);
    },
    [context, trackingData, customConfig],
  );

  const closeRecord = React.useCallback(() => context.closeRecord(), [context]);

  return { ...context, viewRecord, closeRecord };
}
