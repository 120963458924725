import React, { useState } from "react";
import { App } from "antd5";

import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useUpdateSignalSettings } from "lib/hooks/api/signals/useUpdateSignalSettings";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { settingsRoutes } from "lib/routes";
import { usePaywall } from "../../../../lib/providers/Paywall";
import { Item, SaveToList } from "./SaveToList";

export function SaveToCompetitorOrPartner({
  supplierGuid,
  creation,
  setCreation,
}: {
  supplierGuid: string;
  creation: boolean;
  setCreation: (creation: boolean) => void;
}) {
  const { message } = App.useApp();

  const { data: savedSuppliers, isLoading, isError } = useSavedSuppliers();

  const [searchText, setSearchText] = useState("");
  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Save Supplier",
  });
  const { openPaywallModal } = usePaywall();

  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };
  const isPartner = partnerGuids.some((guid) => guid === supplierGuid);
  const isCompetitor = competitorGuids.some((guid) => guid === supplierGuid);

  const { mutate: updateSignalSettings } = useUpdateSignalSettings({
    onSuccess: (_data, variables, _context) => {
      const { competitorIds, partnerIds } = variables.input;
      if (competitorIds !== undefined && competitorIds !== null) {
        message.success("Competitor signals saved successfully");
      }
      if (partnerIds !== undefined && partnerIds !== null) {
        message.success("Partner signals saved successfully");
      }
    },
    onError: () => {
      message.error("An error occurred saving competitor and partner signals");
    },
  });

  const onClick = (signalType: "competitor" | "partner") => {
    if (!hasSuppliers) {
      openPaywallModal(signalType === "competitor" ? "COMPETITORS" : "PARTNERS", {
        contextSource: "Save Supplier",
      });
      return;
    }
    if (signalType === "competitor") {
      if (isCompetitor) {
        updateSignalSettings({
          input: {
            competitorIds: competitorGuids.filter(
              (competitorGuid) => competitorGuid !== supplierGuid,
            ),
          },
        });
      } else {
        updateSignalSettings({
          input: { competitorIds: [...competitorGuids, supplierGuid].flat() },
        });
      }
    }
    if (signalType === "partner") {
      if (isPartner) {
        updateSignalSettings({
          input: {
            partnerIds: partnerGuids.filter((partnerId) => partnerId !== supplierGuid),
          },
        });
      } else {
        updateSignalSettings({
          input: { partnerIds: [...partnerGuids, supplierGuid].flat() },
        });
      }
    }
  };

  const allItems = [
    {
      label: "My competitors",
      isSaved: isCompetitor,
      id: "competitor",
      viewUrl: settingsRoutes.signalSettings,
      paywall: !hasSuppliers,
    },
    {
      label: "My partners",
      isSaved: isPartner,
      id: "partner",
      viewUrl: settingsRoutes.signalSettings,
      paywall: !hasSuppliers,
    },
  ] as Item[];
  const filteredItems = filterBySearchText(searchText, allItems);

  return (
    <SaveToList
      items={filteredItems}
      isLoading={isLoading}
      isError={isError}
      onItemClick={(id, _) => onClick(id as "competitor" | "partner")}
      entityId={supplierGuid}
      entityType="supplier"
      onSearch={(text) => setSearchText(text)}
      creation={creation}
      setCreation={setCreation}
    />
  );
}

function filterBySearchText(searchText: string, allListItems: Item[]) {
  return searchText
    ? allListItems.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))
    : allListItems;
}
