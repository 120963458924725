import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import { App, Button, Skeleton } from "antd5";
import { capitalize } from "lodash";

import { routes } from "lib/routes";
import { TrackingProvider } from "lib/tracking";
import CentredSpinner from "../../../../lib/core_components/CentredSpinner";
import { OpportunityEntities_2_EntityTypeEnum_1 } from "../../../../lib/generated/app-service-gql/graphql";
import { useAddOpportunityEntity } from "../../../../lib/hooks/api/opportunities/useAddOpportunityEntity";
import { useEntityOpportunities } from "../../../../lib/hooks/api/opportunities/useEntityOpportunities";
import { OpportunityFromSearch } from "../../../../lib/hooks/api/opportunities/useOpportunities";
import { useRemoveOpportunityEntity } from "../../../../lib/hooks/api/opportunities/useRemoveOpportunityEntity";
import UIcon from "../../../../lib/icons/untitled_ui/UIcon";
import {
  sysBorderPrimary,
  sysPrimaryDefault,
  sysPrimarySubtle,
} from "../../../../lib/themes/colors";
import { OpportunityEntityType } from "../../../../lib/types/graphQLEnums";
import { Flex, Text } from "../../../../styles/utility-components";
import EmptyState from "../../../ui/empty_state/EmptyState";
import { ActionMessageContent } from "../ActionMessageContent";
import { convertEntityTypeToOpportunityEntityType, EntityType } from "../types";
import { LIST_CONTAINER_ID } from "./SaveToOpportunity";

export function SaveToOpportunityList({
  isLoading,
  isError,
  opportunities,
  entityId,
  entityType,
  fetchNextPage,
  hasNextPage,
}: {
  isLoading: boolean;
  isError: boolean;
  opportunities: OpportunityFromSearch[];
  entityId: string;
  entityType: EntityType;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}) {
  const { data: savedOpportunityIds, isLoading: isLoadingSavedOpp } =
    useEntityOpportunities(entityId);
  if (isLoading || isLoadingSavedOpp) {
    return <Skeleton active />;
  }

  if (isError) {
    return (
      <EmptyState
        type="loadError"
        heading="Sorry something has gone wrong loading opportunities"
        description="If this error persists please contact support"
        width="100%"
        size="large"
      />
    );
  }

  if (opportunities.length === 0) {
    return (
      <EmptyState
        heading="You haven't added any opportunities yet"
        description="Create opportunities once you identify leads from Stotles, which can then be tracked and progressed our public sector pipeline."
        width="100%"
        size="large"
      />
    );
  }

  return (
    <InfiniteScroll
      dataLength={opportunities.length}
      next={fetchNextPage}
      hasMore={hasNextPage || false}
      loader={<CentredSpinner />}
      scrollableTarget={LIST_CONTAINER_ID}
      style={{
        height: "inherit",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 8,
      }}
    >
      {opportunities.map((opportunity) => (
        <TrackingProvider
          key={opportunity.id}
          data={{
            "Opportunity name": opportunity.name,
            "Opportunity stage": opportunity.stage.name,
            "Opportunity value": opportunity.value,
          }}
        >
          <OpportunityListItem
            opportunity={opportunity}
            entityId={entityId}
            entityType={entityType}
            isSaved={savedOpportunityIds.includes(opportunity.id)}
          />
        </TrackingProvider>
      ))}
    </InfiniteScroll>
  );
}

function OpportunityListItem({
  opportunity,
  entityId,
  entityType,
  isSaved,
}: {
  opportunity: OpportunityFromSearch;
  entityId: string;
  entityType: EntityType;
  isSaved: boolean;
}) {
  const { message } = App.useApp();

  const { mutate: addEntity } = useAddOpportunityEntity({
    onSuccess: () => {
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`${capitalize(entityType)} saved to ${opportunity.name}`}
            objectType="opportunity"
            url={`${routes.opportunities}/${opportunity.id}`}
          />
        ),
      });
    },
    onError: () => message.error(`Failed to save ${entityType} to ${opportunity.name}`),
  });

  const { mutate: removeEntity } = useRemoveOpportunityEntity({
    onSuccess: () => {
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`${capitalize(entityType)} removed from ${opportunity.name}`}
            objectType="opportunity"
            url={`${routes.opportunities}/${opportunity.id}`}
          />
        ),
      });
    },
    onError: () => message.error(`Failed to remove ${entityType} from ${opportunity.name}`),
  });

  const onToggleSave = () => {
    if (isSaved) {
      return removeEntity({
        input: {
          opportunityId: opportunity.id,
          entityId,
          entityType: convertEntityTypeToOpportunityEntityType(
            entityType,
            OpportunityEntities_2_EntityTypeEnum_1,
          ),
        },
      });
    }
    addEntity({
      id: opportunity.id,
      entites: {
        entities: [
          {
            entityId,
            entityType: convertEntityTypeToOpportunityEntityType(entityType, OpportunityEntityType),
          },
        ],
      },
    });
  };

  return (
    <OpportunityItem key={opportunity.id}>
      <Flex column minWidth={0}>
        <Text ellipsis>{opportunity.name}</Text>
        <Text ellipsis>{opportunity.description}</Text>
      </Flex>
      <ActionsContainer>
        <Button
          type="primary"
          icon={
            <UIcon
              icon="bookmark"
              size={16}
              fill={isSaved ? "currentColor" : "none"}
              color="currentColor"
            />
          }
          aria-label={
            isSaved
              ? `Remove from opportunity ${opportunity.name}`
              : `Add to opportunity ${opportunity.name}`
          }
          onClick={onToggleSave}
        >
          {isSaved ? "Unsave" : "Save"}
        </Button>
        <Button target="_blank" href={`${routes.opportunities}/${opportunity.id}`}>
          View
        </Button>
      </ActionsContainer>
    </OpportunityItem>
  );
}

const ActionsContainer = styled.div({
  display: "none",
  gap: 4,
});

const OpportunityItem = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 16,
  height: 70,
  border: "1px solid",
  borderRadius: 4,
  padding: "12px 16px",
  borderColor: sysBorderPrimary,
  alignItems: "center",
  transition: "background-color 140ms ease-out, border-color 140ms ease-out",
  "&:hover": {
    backgroundColor: sysPrimarySubtle,
    borderColor: sysPrimaryDefault,
    [`${ActionsContainer}`]: {
      display: "flex",
    },
  },
}));
