import React, { useState } from "react";
import styled from "@emotion/styled";
import { App, Button, Input } from "antd5";
import { capitalize } from "lodash";

import { routes } from "lib/routes";
import { EventNames, useTracking } from "lib/tracking";
import { useDebouncedValue } from "../../../../lib/debounce";
import {
  SearchOpportunitiesRequest,
  SearchOpportunitiesRequest_OrderByEnum_1,
  SearchOpportunitiesRequest_OrderEnum_1,
} from "../../../../lib/generated/app-service-gql/graphql";
import { useInfiniteOpportunities } from "../../../../lib/hooks/api/opportunities/useOpportunities";
import UIcon from "../../../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../../../styles/utility-components";
import { CreateOpportunityForm } from "../../create_opportunity/CreateOpportunityForm";
import { ActionMessageContent } from "../ActionMessageContent";
import { SharedSaveProps } from "../types";
import { SaveToOpportunityList } from "./SaveToOpportunityList";

export const LIST_CONTAINER_ID = "save-to-opportunity-list";
type SaveToOpportunityProps = SharedSaveProps & {
  creation: boolean;
  setCreation: (state: boolean) => void;
  closeDrawer: () => void;
};

export function SaveToOpportunity({
  defaultOpportunityValues,
  entityId,
  entityType,
  creation,
  setCreation,
  closeDrawer,
}: SaveToOpportunityProps) {
  const { logEvent } = useTracking();
  const { message } = App.useApp();

  const [filters, setFilters] = useState<SearchOpportunitiesRequest>({
    orderBy: SearchOpportunitiesRequest_OrderByEnum_1.CreatedAt,
    order: SearchOpportunitiesRequest_OrderEnum_1.Desc,
  });
  const [debouncedFilters] = useDebouncedValue(filters, 500);
  const { data, hasNextPage, fetchNextPage, isLoading, isError } =
    useInfiniteOpportunities(debouncedFilters);

  const allOpportunities = data?.pages.flatMap((page) => page.opportunities.results) ?? [];

  if (creation) {
    return (
      <Flex column gap={16} height="100%">
        <Text h2>Create new opportunity</Text>
        <CreateOpportunityForm
          onCancel={() => setCreation(false)}
          onSuccess={(id?: string, name?: string) => {
            setCreation(false);
            closeDrawer();
            message.open({
              icon: null,
              content: (
                <ActionMessageContent
                  message={`${capitalize(entityType)} saved to ${name}`}
                  objectType="opportunity"
                  url={`${routes.opportunities}/${id}`}
                />
              ),
            });
          }}
          defaultValues={defaultOpportunityValues}
          footer={
            <Flex column gap={8} flexGrow={1} justifyContent="flex-end">
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Flex>
          }
        />
      </Flex>
    );
  }

  return (
    <Flex column gap={16} height={"100%"}>
      <Text h3>Save to an opportunity</Text>
      <Input
        placeholder="Search"
        prefix={<UIcon icon="searchRefraction" size={16} />}
        value={filters.searchText ?? ""}
        onChange={(e) => {
          setFilters({ ...filters, searchText: e.target.value });
        }}
        allowClear
      />
      <ListContainer id={LIST_CONTAINER_ID}>
        <SaveToOpportunityList
          isLoading={isLoading}
          isError={isError}
          opportunities={allOpportunities}
          entityId={entityId}
          entityType={entityType}
          hasNextPage={hasNextPage || false}
          fetchNextPage={fetchNextPage}
        />
      </ListContainer>
      <Button
        type="primary"
        onClick={() => {
          setCreation(true);
          logEvent(EventNames.createOpportunityStarted);
        }}
      >
        Create new opportunity
      </Button>
    </Flex>
  );
}

const ListContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  gap: 8,
  height: "100%",
  overflowY: "auto",
});
