import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { getOpportunityStageById } from "components/opportunities/utils";
import { graphql } from "lib/generated/app-service-gql";
import {
  CreateOpportunityMutation,
  CreateOpportunityMutationVariables,
  OpportunityEntitiesInput_Entities_EntityTypeEnum_1,
} from "lib/generated/app-service-gql/graphql";
import { EventNames, useTracking } from "lib/tracking";
import { updateCachedContactOpportunities } from "../contacts/utils";
import { updateCachedNoticeOpportunities } from "../notices/utils";
import { useGraphQlMutation } from "../useGraphQlClient";
import { useOpportunityWorkflow } from "./useOpportunityWorkflow";
import { updateCachedEntityOpportunities } from "./utils";

const createOpportunity = graphql(`
  mutation createOpportunity(
    $opportunity: CreateOpportunity!
    $entities: OpportunityEntitiesInput!
  ) {
    createOpportunity(entities: $entities, opportunity: $opportunity) {
      id
    }
  }
`);

export function useCreateOpportunity(
  options?: UseMutationOptions<
    CreateOpportunityMutation,
    unknown,
    CreateOpportunityMutationVariables,
    unknown
  >,
) {
  const { logEvent } = useTracking();
  const { data: workflow } = useOpportunityWorkflow();

  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(createOpportunity, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(["opportunities"]);

      for (const entity of variables.entities.entities) {
        updateCachedEntityOpportunities({
          queryClient,
          entityId: entity.entityId,
          opportunityId: data.createOpportunity.id,
          isAdding: true,
        });

        if (entity.entityType === OpportunityEntitiesInput_Entities_EntityTypeEnum_1.Notice) {
          updateCachedNoticeOpportunities({
            queryClient,
            lookupId: entity.entityId,
            opportunityId: data.createOpportunity.id,
            isAdding: true,
          });
        }

        if (entity.entityType === OpportunityEntitiesInput_Entities_EntityTypeEnum_1.Contact) {
          updateCachedContactOpportunities({
            queryClient,
            contactId: entity.entityId,
            opportunityId: data.createOpportunity.id,
            isAdding: true,
          });
        }

        // TODO: Add update for opportunity entities
      }

      options?.onSuccess?.(data, variables, context);

      const opportunityStage = getOpportunityStageById(
        variables.opportunity.stageId,
        workflow?.transitions ?? [],
      );

      logEvent(EventNames.opportunityCreated, {
        "Opportunity stage": opportunityStage?.stage.name,
        "Opportunity value": variables.opportunity.value,
      });
    },
  });
  return { data: data?.createOpportunity, ...rest };
}
