import React, { useEffect, useMemo } from "react";
import { useForm, UseFormSetValue } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

import { Select } from "components/form_components/Inputs";
import { useBuyers } from "lib/hooks/api/buyer/useBuyers";
import { OpportunityEntityType } from "lib/types/graphQLEnums";
import { BuyerSelect } from "../../form_components/BuyerSelect";
import { CreateOpportunityFormValues } from "./CreateOpportunityForm";

// Had to do something custom due to the unique requirements of this form
// as we do not know if an organisation is a buyer or supplier, just the guid
export const BuyerMultiSelect = ({
  defaultEntities: defaultEntities = [],
  setValue,
}: {
  defaultEntities: CreateOpportunityFormValues["entities"];
  setValue: UseFormSetValue<CreateOpportunityFormValues>;
}) => {
  const defaultOrganisations = defaultEntities?.filter(
    (entity) => entity.entityType === OpportunityEntityType.Organisation,
  );
  const hasDefaultOrganisations = defaultOrganisations.length > 0;

  const { data, isLoading } = useBuyers(
    {
      guids: defaultOrganisations.map((entity) => entity.entityId),
    },
    { enabled: hasDefaultOrganisations, staleTime: Infinity, cacheTime: Infinity },
  );
  const defaultBuyers = useMemo(() => data?.results.map((buyer) => buyer.guid) ?? [], [data]);
  // To avoid re-fetching the data in BuyerSelect, we add it to the query cache here
  // since the query params there are a subset of the query params above but will output the same data
  useQuery(["buyers", { guids: defaultBuyers }], () => Promise.resolve(data), {
    enabled: !isLoading && hasDefaultOrganisations,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const { control, watch, reset } = useForm({
    defaultValues: {
      emptyBuyers: [],
      buyers: defaultBuyers,
    },
  });

  useEffect(() => {
    reset({
      emptyBuyers: [],
      buyers: defaultBuyers,
    });
  }, [defaultBuyers, reset]);

  const watched = watch("buyers");

  useEffect(() => {
    setValue("entities", [
      ...defaultEntities.filter((entity) => !defaultBuyers.includes(entity.entityId)),
      ...watched.map((entityId) => ({ entityId, entityType: OpportunityEntityType.Organisation })),
    ]);
  }, [watched, defaultEntities, defaultBuyers, setValue]);

  if (isLoading && hasDefaultOrganisations) {
    return (
      <Select
        name="emptyBuyers"
        control={control}
        label="Buyers"
        placeholder="Loading..."
        options={[]}
        loading
      />
    );
  }

  return <BuyerSelect name="buyers" control={control} label="Buyers" mode="multiple" allowClear />;
};
