import React, { useState } from "react";
import styled from "@emotion/styled";
import { Drawer, Segmented } from "antd5";

import Button from "components/ui/button/Button";
import { TrackingProvider } from "lib/tracking";
import { Flex } from "../../../styles/utility-components";
import { SaveToLists } from "./lists/SaveToLists";
import { SaveToOpportunity } from "./opportunity/SaveToOpportunity";
import { EntityType, SharedSaveProps } from "./types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & SharedSaveProps;

const hasList: Record<EntityType, boolean> = {
  buyer: true,
  supplier: true,
  notice: true,
  framework: false,
  document: false,
  contact: false,
};

export function SaveToDrawer({
  isOpen,
  onClose,
  entityType,
  entityId,
  entityListId,
  defaultOpportunityValues,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<"opportunity" | "list">(
    entityType === "supplier" ? "list" : "opportunity",
  );
  const [creation, setCreation] = useState(false);

  const showTabs = hasList[entityType] && !creation;

  return (
    <Drawer
      placement="right"
      closable={true}
      open={isOpen}
      width={486}
      onClose={onClose}
      styles={{ header: { display: "none" }, body: { padding: "16px 24px" } }}
    >
      <TrackingProvider data={{ "Data type": entityType.toUpperCase() }}>
        <Flex gap={32} height="100%">
          <Flex column height="100%" gap={24} width="440px">
            <HeaderButtonsContainer justifyContent="space-between" alignItems="center">
              {creation ? (
                <Button
                  as="button"
                  buttonType="secondary"
                  buttonStyle="default"
                  icon="arrowNarrowLeft"
                  onClick={() => setCreation(false)}
                />
              ) : (
                <div />
              )}
              <Button
                as="button"
                buttonType="secondary"
                buttonStyle="default"
                icon="xClose"
                onClick={onClose}
              />
            </HeaderButtonsContainer>
            <Flex column height="calc(100% - 48px)" gap={24}>
              {showTabs && (
                <Segmented
                  options={[
                    { value: "opportunity", label: "Opportunity" },
                    { value: "list", label: "List" },
                  ]}
                  onChange={(value: "opportunity" | "list") => setSelectedTab(value)}
                  value={selectedTab}
                  block
                />
              )}
              <Flex column height={showTabs ? "calc(100% - 56px)" : "100%"}>
                {selectedTab === "opportunity" || !hasList[entityType] ? (
                  <SaveToOpportunity
                    entityType={entityType}
                    entityId={entityId}
                    defaultOpportunityValues={defaultOpportunityValues}
                    creation={creation}
                    setCreation={setCreation}
                    closeDrawer={onClose}
                  />
                ) : (
                  <SaveToLists
                    entityType={entityType}
                    entityId={entityListId ?? entityId}
                    creation={creation}
                    setCreation={setCreation}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </TrackingProvider>
    </Drawer>
  );
}

const HeaderButtonsContainer = styled(Flex)({
  marginLeft: "-8px",
  marginRight: "-8px",
});
