import * as React from "react";

import Button from "components/ui/button/Button";
import { ProviderTypes } from "lib/generated/integration-api";
import { BrandIconKey } from "lib/icons/untitled_ui/UIcon";
import { hubspotOrange, salesforceBlue } from "lib/themes/colors";
import { EventNames, useTracking } from "lib/tracking";

export type ButtonTypes = "primary" | "secondary" | "tertiary";

export const providerIconKeyMap: Record<ProviderTypes, BrandIconKey> = {
  HUBSPOT: "hubspot",
  SALESFORCE: "salesforce",
};

export const providerIconColorMap: Record<ProviderTypes, string> = {
  HUBSPOT: hubspotOrange,
  SALESFORCE: salesforceBlue,
};

type ViewInCRMProps = {
  providerType: ProviderTypes;
  externalUrl: string;
  buttonType?: ButtonTypes;
  disabled?: boolean;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
};

/**
 * Link that looks like a button and lets you view the resource in the CRM
 */
export function ViewInCRMButton({
  providerType,
  externalUrl,
  type = "primary",
  fullWidth = false,
  align,
}: ViewInCRMProps) {
  const { logEvent } = useTracking();
  const trackingAttributes = React.useMemo(() => {
    return {
      "Connected provider": providerType,
    };
  }, [providerType]);
  return (
    <Button
      buttonType={type}
      icon={providerIconKeyMap[providerType]}
      iconProps={{
        color: type === "primary" ? undefined : providerIconColorMap[providerType],
      }}
      as="a"
      target="new-tab"
      href={externalUrl}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        logEvent(EventNames.viewInCRMButtonClicked, trackingAttributes);
      }}
      fullWidth={fullWidth}
      justifyContent={align}
    >
      View
    </Button>
  );
}

type SendToCRMProps = {
  providerType: ProviderTypes;
  buttonType?: ButtonTypes;
  loading?: boolean;
  onClick: (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void | Promise<void>;
  disabled?: boolean;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
  eventName: EventNames; // This button can be used to open the modal or send the form
};

/**
 * Button that is used to send items to the CRM depending on onSend passed in
 */
export function SendToCRMButton({
  providerType,
  onClick,
  loading,
  disabled,
  type = "secondary",
  fullWidth = false,
  eventName,
  align,
}: SendToCRMProps) {
  const { logEvent } = useTracking();
  const trackingAttributes = React.useMemo(() => {
    return {
      "Connected provider": providerType,
      "Data types": "Notice",
    };
  }, [providerType]);

  return (
    <Button
      buttonType={type}
      onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
        logEvent(eventName, trackingAttributes);
        await onClick(e);
      }}
      disabled={disabled}
      icon={providerIconKeyMap[providerType]}
      iconProps={{
        color: providerIconColorMap[providerType],
      }}
      as="button"
      fullWidth={fullWidth}
      justifyContent={align}
      blackText
    >
      {loading ? "Loading" : "Send"}
    </Button>
  );
}
