import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "../../../generated/app-service-gql";
import {
  OpportunityEntities_2_EntityTypeEnum_1,
  RemoveOpportunityEntityMutation,
  RemoveOpportunityEntityMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { EventNames, useTracking } from "../../../tracking";
import { updateCachedContactOpportunities } from "../contacts/utils";
import { updateCachedNoticeOpportunities } from "../notices/utils";
import { useGraphQlMutation } from "../useGraphQlClient";
import { updateCachedEntityOpportunities } from "./utils";

const REMOVE_OPPORTUNITY_ENTITY_MUTATION = graphql(`
  mutation removeOpportunityEntity($input: OpportunityEntities_2!) {
    removeOpportunityEntity(OpportunityEntity: $input) {
      id
    }
  }
`);

export function useRemoveOpportunityEntity(
  options?: UseMutationOptions<
    RemoveOpportunityEntityMutation,
    unknown,
    RemoveOpportunityEntityMutationVariables,
    unknown
  >,
) {
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();

  return useGraphQlMutation(REMOVE_OPPORTUNITY_ENTITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      // Update entity opportunities cache
      updateCachedEntityOpportunities({
        queryClient,
        entityId: variables.input.entityId,
        opportunityId: variables.input.opportunityId,
        isAdding: false,
      });

      // Update notice cache if entity is a notice
      if (variables.input.entityType === OpportunityEntities_2_EntityTypeEnum_1.Notice) {
        updateCachedNoticeOpportunities({
          queryClient,
          lookupId: variables.input.entityId,
          opportunityId: variables.input.opportunityId,
          isAdding: false,
        });
      }

      // Update contact cache if entity is a contact
      if (variables.input.entityType === OpportunityEntities_2_EntityTypeEnum_1.Contact) {
        updateCachedContactOpportunities({
          queryClient,
          contactId: variables.input.entityId,
          opportunityId: variables.input.opportunityId,
          isAdding: false,
        });
      }

      // TODO: Add more entity types as they are ported over to GraphQL

      options?.onSuccess?.(data, variables, context);
      logEvent(EventNames.opportunityDataRemoved);
    },
  });
}
