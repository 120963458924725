import { QueryClient } from "@tanstack/react-query";

import { RecordDto, RecordSearchResponseDto } from "lib/generated/app-api";
import { NoticeQuery, NoticesQuery } from "lib/generated/app-service-gql/graphql";
import { NoticeDetails } from "./useNotice";
import { NoticesDto } from "./useNotices";

export function updateCachedRecord(
  queryClient: QueryClient,
  recordId: string,
  updateRecord: (r: RecordDto) => RecordDto,
) {
  queryClient.setQueryData<RecordDto>(["record", recordId], (result) => {
    if (!result) {
      return result;
    }

    return updateRecord(result);
  });

  queryClient.setQueriesData<RecordSearchResponseDto>(["records"], (resp) => {
    if (!resp || !resp.results) {
      return resp;
    }
    const results = [...resp.results];
    const recordIndex = results.findIndex((r) => r.guid === recordId);
    if (recordIndex === -1) {
      return resp;
    }

    results[recordIndex] = updateRecord(results[recordIndex]);
    return {
      ...resp,
      results,
    };
  });
}

export function updateCachedNotice(
  queryClient: QueryClient,
  noticeId: string,
  updateNotice: (r: NoticeDetails) => NoticeDetails,
) {
  queryClient.setQueriesData<NoticeQuery>(["notice", noticeId], (resp) => {
    if (!resp?.notice) {
      return resp;
    }

    return { notice: updateNotice(resp.notice) };
  });
}

export function updateCachedNotices(
  queryClient: QueryClient,
  lookupId: string,
  updateNotice: (r: NoticesDto) => NoticesDto,
  useProcurementStageId: boolean = false,
) {
  queryClient.setQueriesData<NoticesQuery>(["notices"], (resp) => {
    if (!resp?.notices.results) {
      return resp;
    }
    const results = [...resp.notices.results];
    const noticeIndex = results.findIndex((r) =>
      useProcurementStageId ? r.procurementStage?.id === lookupId : r.guid === lookupId,
    );

    if (noticeIndex === -1) {
      return resp;
    }

    results[noticeIndex] = updateNotice(results[noticeIndex]);

    return {
      notices: { ...resp.notices, results },
    };
  });
}

export function updateCachedNoticeOpportunities({
  queryClient,
  lookupId,
  opportunityId,
  isAdding,
}: {
  queryClient: QueryClient;
  lookupId: string;
  opportunityId: string;
  isAdding: boolean;
}) {
  updateCachedNotices(
    queryClient,
    lookupId,
    (notice) => {
      const opportunities = notice.opportunities || [];

      if (isAdding) {
        if (!opportunities.find((opp) => opp.id === opportunityId)) {
          return {
            ...notice,
            opportunities: [...opportunities, { id: opportunityId }],
          };
        }
      }

      return {
        ...notice,
        opportunities: isAdding
          ? opportunities
          : opportunities.filter((opp) => opp.id !== opportunityId),
      };
    },
    true,
  );
}
