import React, { useState } from "react";
import { Select } from "antd5";

import SkeletonText from "components/ui/skeleton/SkeletonText";
import { useDebouncedValue } from "lib/debounce";
import {
  SignalToMigrate,
  useSignalsToMigrate,
} from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import { OrgBasicInfo } from "lib/hooks/api/organisations/useSearchOrganisations";
import { deduplicateByKey } from "lib/utils";

import css from "./SignalsToMigrateSelection.module.scss";

export function deduplicateOrgs(orgs: OrgBasicInfo[]): OrgBasicInfo[] {
  return Array.from(
    deduplicateByKey(orgs, (org: OrgBasicInfo) => {
      return org.id;
    }),
  );
}

type Props = {
  selectedSignals: SignalToMigrate[];
  onSignalsChange: (selectedOrgs: SignalToMigrate[]) => void;
  addOrgsFromNewSignals: (selectedOrgs: SignalToMigrate[]) => void;
};

function SignalsToMigrateSelection({
  selectedSignals,
  onSignalsChange,
  addOrgsFromNewSignals,
}: Props): JSX.Element {
  const [textSearch, setTextSearch] = useState<string>("");
  const [debouncedText] = useDebouncedValue(textSearch, 300);

  // Searching within ALL signals, not just within current feed settings
  const { isLoading, data } = useSignalsToMigrate({
    textSearch: debouncedText,
  });

  const signalResults = data?.signalsToMigrate || [];

  return (
    <div>
      <h3>Choose supplier signals to migrate</h3>
      <Select
        className={css.searchInput}
        loading={isLoading}
        showSearch
        size="large"
        onSearch={setTextSearch}
        mode="multiple"
        value={selectedSignals.map((signal) => signal.name)}
        options={signalResults.map((signal) => ({
          key: signal.name,
          value: signal.name,
          label: `${signal.name} (${signal.ids.length})`,
        }))}
        optionFilterProp="value"
        placeholder="Search by signal name"
        onChange={(selectedNames) => {
          const newSelectedSignals = signalResults.filter((signal) =>
            selectedNames.includes(signal.name),
          );

          addOrgsFromNewSignals(newSelectedSignals);

          const retainedSignals = selectedSignals.filter((signal) =>
            selectedNames.includes(signal.name),
          );
          const allSignals = Array.from(
            deduplicateByKey(
              [...newSelectedSignals, ...retainedSignals],
              (signal: SignalToMigrate) => {
                return signal.name;
              },
            ),
          );

          onSignalsChange(allSignals);
        }}
        notFoundContent={
          isLoading ? (
            <span className={css.loadingSkeleton}>
              <SkeletonText width="100%" />
              <SkeletonText width="100%" />
              <SkeletonText width="100%" />
            </span>
          ) : null
        }
      />
    </div>
  );
}

export default SignalsToMigrateSelection;
