import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Checkbox, Divider, Drawer, Input, Select } from "antd5";
import { CheckboxChangeEvent } from "antd5/lib/checkbox";
import { isEqual } from "lodash";

import BuyerCategoryTreeSelect from "components/form_components/BuyerCategoryTreeSelect";
import { BuyerListSelect } from "components/form_components/BuyerListSelect";
import BuyerMultiselect from "components/form_components/BuyerMultiselect";
import { FilterWithDisabledPopup } from "components/form_components/FilterWithDisabledPopup";
import { useSignalSettingCountryCodes } from "lib/hooks/api/teams/useSignalSettings";
import { EventNames, logEvent } from "lib/tracking";
import { JobFunction } from "lib/utils/oscarDataUtils";
import { white } from "../../lib/themes/colors";
import {
  CATEGORY_TYPES,
  ContactDisplayOption,
  ContactFilters,
  SENIORITY_OPTIONS,
} from "./contactUtils";
import { FilterForm } from "./FilterForm";

import css from "./ContactsFilterDrawer.module.scss";

type ContactsFilterDrawerProps = {
  visible: boolean;
  filtersApplied: boolean;
  contactFilters: ContactFilters;
  clearFilters: () => void;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSearchFilters: (filters: Partial<ContactFilters>) => void;
  handleTextSearch: (value: string) => void;
  withinContextOfBuyer?: boolean;
};

function ContactsFilterDrawer({
  visible,
  filtersApplied,
  contactFilters,
  withinContextOfBuyer,
  toggleDrawer,
  clearFilters,
  onChangeSearchFilters,
  handleTextSearch,
}: ContactsFilterDrawerProps): JSX.Element {
  const [activeGroups, setActiveGroups] = useState<string[]>([]);
  const isContactFilterFormEnabled = useVariableValue("filter-collapse-contacts", false);
  const [formState, setFormState] = useState<ContactFilters>(contactFilters);

  const userCountryCodes = useSignalSettingCountryCodes();

  const onChange = (newFilters: Partial<ContactFilters>) => {
    setFormState({ ...formState, ...newFilters });
    onChangeSearchFilters({ ...newFilters });
  };

  // Effect to update local form state when contactsFilter changes
  useEffect(() => {
    if (!isEqual(contactFilters, formState)) {
      setFormState(contactFilters);
    }
    // disabled as we only want to run this effect when contactFilters change not when formState changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactFilters]);

  const onChangeContactInformation = (e: CheckboxChangeEvent, contact_op: ContactDisplayOption) => {
    let contactDisplayOps = contactFilters.contactDisplayOptions;

    if (e.target.checked) {
      contactDisplayOps.push(contact_op);
    } else {
      contactDisplayOps = contactDisplayOps.filter((item) => item !== contact_op);
    }

    onChange({ contactDisplayOptions: contactDisplayOps });

    logEvent(EventNames.filterActioned, {
      "Filter name": "Contact information",
      "Filter selection": contactDisplayOps,
      "Action type": contactDisplayOps.length > 0 ? "Filter applied" : "Filter cleared",
    });
  };

  return (
    <Drawer
      width={700}
      className={css.drawer}
      styles={{ body: { padding: 0 }, content: { backgroundColor: white } }}
      open={visible}
      closeIcon={!isContactFilterFormEnabled}
      onClose={() => toggleDrawer(false)}
    >
      {isContactFilterFormEnabled ? (
        <FilterForm
          onChange={onChangeSearchFilters}
          onClose={() => toggleDrawer(false)}
          filters={contactFilters}
          activeGroups={activeGroups}
          onActiveGroupChange={setActiveGroups}
        />
      ) : (
        <>
          <header className={css.titleBar}>
            <h2>Contact Filters</h2>
            {filtersApplied && (
              <Button type="link" className={css.clearFiltersBtn} onClick={clearFilters}>
                Clear all
              </Button>
            )}
          </header>
          <Divider />

          <div className={css.contactFilters}>
            <h2 className={css.subtitle}>Contact Information</h2>
            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Job title</span>
              <Input
                placeholder="Search job titles"
                onChange={(e) => {
                  onChange({ jobTitle: e.target.value });
                  handleTextSearch(e.target.value);
                }}
                value={formState.jobTitle}
                className={css.searchInput}
                prefix={<SearchOutlined className={css.searchIcon} />}
                allowClear
              />
            </div>
            <div className={css.inputGroup}>
              <span className={css.inputLabel}>First name</span>
              <Input
                placeholder="Search names"
                onChange={(e) => onChange({ firstName: e.target.value })}
                value={formState.firstName}
                className={css.searchInput}
                allowClear
              />
            </div>
            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Last name</span>
              <Input
                placeholder="Search names"
                onChange={(e) => onChange({ lastName: e.target.value })}
                value={formState.lastName}
                className={css.searchInput}
                allowClear
              />
            </div>
            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Seniority</span>
              <Select<string[]>
                mode="multiple"
                placeholder="Select"
                value={formState.seniority}
                style={{ width: "100%" }}
                allowClear
                filterOption={(text, option) =>
                  (option?.props.label || "").toLowerCase().includes(text.toLowerCase())
                }
                onChange={(e) => onChange({ seniority: e as string[] })}
              >
                {SENIORITY_OPTIONS.map((op) => (
                  <Select.Option value={op.value} key={op.value} label={op.title}>
                    {op.title}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Function</span>
              <Select<string[]>
                mode="multiple"
                placeholder="Select"
                value={formState.jobFunction}
                style={{ width: "100%" }}
                allowClear
                filterOption={(text, option) =>
                  (option?.props.label || "").toLowerCase().includes(text.toLowerCase())
                }
                onChange={(e) => onChange({ jobFunction: e as JobFunction[] })}
              >
                {CATEGORY_TYPES.map((cat) => (
                  <Select.Option value={cat.value} key={cat.value} label={cat.title}>
                    {cat.title}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Contact Information</span>
              <span className={css.subtitleLabel}>Must contain</span>

              <Checkbox
                checked={formState.contactDisplayOptions.includes("EMAIL")}
                onChange={(e) => onChangeContactInformation(e, "EMAIL")}
                className={css.contactCheckbox}
              >
                Email address
              </Checkbox>

              <Checkbox
                checked={formState.contactDisplayOptions.includes("PHONE")}
                onChange={(e) => onChangeContactInformation(e, "PHONE")}
                className={css.contactCheckbox}
              >
                Phone number
              </Checkbox>
            </div>

            {!withinContextOfBuyer && (
              <>
                <Divider />
                <h2 className={css.subtitle}>Buyer Information</h2>

                <FilterWithDisabledPopup
                  filterTitle={<span className={css.inputLabel}>Buyer lists</span>}
                  popupTitle="Buyer list filter disabled"
                  popupDescription="Clear buyers filter to enable searching by specific buyer lists"
                  filterDisabled={!!formState.buyerGuids?.length}
                  clearFilter={() => onChange({ buyerGuids: [] })}
                  filterComponent={
                    <BuyerListSelect
                      onSelect={(listOfBuyerIds) => onChange({ buyerListIds: listOfBuyerIds })}
                      selected={formState.buyerListIds}
                      disabled={!!formState.buyerGuids?.length}
                    />
                  }
                />

                <FilterWithDisabledPopup
                  filterTitle={<span className={css.inputLabel}>Buyers</span>}
                  popupTitle="Buyers filter disabled"
                  popupDescription="Clear buyer list filter to enable searching by specific buyers"
                  filterDisabled={!!formState.buyerListIds?.length}
                  clearFilter={() => onChange({ buyerListIds: [] })}
                  filterComponent={
                    <BuyerMultiselect<"guid">
                      customPlaceholder="Search by buyer name"
                      identifier="guid"
                      countryCodes={userCountryCodes}
                      buyerIds={formState.buyerGuids}
                      onBuyerIdsChange={(buyerGuids) => onChange({ buyerGuids })}
                      disabled={!!formState.buyerListIds?.length}
                    />
                  }
                />

                <div className={css.inputGroup}>
                  <span className={css.inputLabel}>Buyer type</span>

                  <BuyerCategoryTreeSelect
                    showUncategorised={formState.includeUncategorisedBuyers}
                    selectedIds={formState.buyerCategoryIds || []}
                    onUpdate={(ids, showUncategorised) => {
                      onChange({
                        buyerCategoryIds: ids,
                        includeUncategorisedBuyers: showUncategorised,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
}

export default ContactsFilterDrawer;
