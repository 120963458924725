import React, { useState } from "react";
import { App } from "antd5";

import { generateNoticeSearchUrl } from "components/notices/utils";
import { useAddNoticeToList } from "../../../../lib/hooks/api/notices/lists/useAddNoticeToList";
import { useCreateNoticeList } from "../../../../lib/hooks/api/notices/lists/useCreateNoticeList";
import { useNoticeLists } from "../../../../lib/hooks/api/notices/lists/useNoticeLists";
import { useNoticeListsForRecord } from "../../../../lib/hooks/api/notices/lists/useNoticeListsForRecord";
import { useRemoveNoticeFromList } from "../../../../lib/hooks/api/notices/lists/useRemoveNoticeFromList";
import { ListForm } from "../../../user_lists/UserListsModals";
import { ActionMessageContent } from "../ActionMessageContent";
import { CreateListFormProps, SaveToList } from "./SaveToList";

export function SaveToNoticeList({
  entityId,
  creation,
  setCreation,
}: {
  entityId: string;
  creation: boolean;
  setCreation: (creation: boolean) => void;
}) {
  const { message } = App.useApp();
  const { data: lists, isLoading, isError } = useNoticeLists();
  const { data: savedNoticeLists } = useNoticeListsForRecord(entityId);

  const { mutate: addToList } = useAddNoticeToList({
    onSuccess: (_, variables) =>
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`Notice saved to ${variables.list.name}`}
            objectType="list"
            url={generateNoticeSearchUrl({ noticeLists: [variables.list.id.toString()] })}
          />
        ),
      }),
    onError: (_, variables) => message.error(`Failed to save notice to ${variables.list.name}`),
  });
  const { mutate: removeFromList } = useRemoveNoticeFromList({
    onSuccess: (_, variables) =>
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`Notice removed from ${variables.list.name}`}
            objectType="list"
            url={generateNoticeSearchUrl({ noticeLists: [variables.list.id.toString()] })}
          />
        ),
      }),
    onError: (_, variables) => message.error(`Failed to remove notice from ${variables.list.name}`),
  });
  const [searchValue, setSearchValue] = useState("");

  const items =
    lists?.allLists.map((list) => ({
      id: list.guid,
      label: list.name,
      isSaved: savedNoticeLists?.some((x) => x.guid === list.guid) ?? false,
      viewUrl: generateNoticeSearchUrl({ noticeLists: [list.id.toString()] }),
    })) ?? [];
  const filteredItems = searchValue
    ? items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
    : items;

  const onItemClick = (id: string, isSaved: boolean) => {
    const list = lists?.allLists.find((x) => x.guid === id);
    if (!list) {
      return;
    }

    // TODO: add missing params
    if (isSaved) {
      removeFromList({
        recordGuid: entityId,
        list,
        contextSource: "Save drawer",
        signals: [],
        recordStage: "",
      });
    } else {
      addToList({
        recordGuid: entityId,
        list,
        contextSource: "Save drawer",
        signals: [],
        recordStage: "",
      });
    }
  };

  return (
    <SaveToList
      items={filteredItems}
      isLoading={isLoading}
      isError={isError}
      onItemClick={onItemClick}
      entityId={entityId}
      entityType="notice"
      onSearch={(text) => setSearchValue(text)}
      CreateListForm={CreateNoticeList}
      creation={creation}
      setCreation={setCreation}
    />
  );
}

function CreateNoticeList(props: CreateListFormProps) {
  const { message } = App.useApp();
  const { mutate: createList } = useCreateNoticeList({
    onSuccess: (data) => {
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`Notice saved to ${data.name}`}
            objectType="list"
            url={generateNoticeSearchUrl({ noticeLists: [data.id.toString()] })}
          />
        ),
      });
      props.onSuccess();
    },
    onError: () =>
      message.error("Unable to create your list. Please contact us if the problem persists"),
  });

  return (
    <ListForm
      {...props}
      listName=""
      onSubmit={(newList) => createList({ name: newList.name, entityIds: props.entityIds })}
      isSubmitting={false}
    />
  );
}
