import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { EntityOpportunitiesQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const entityOpportunitiesQuery = graphql(`
  query entityOpportunities($id: String!) {
    entityOpportunites(entityId: $id)
  }
`);

export function useEntityOpportunities(
  id: string,
  options?: UseQueryOptions<EntityOpportunitiesQuery, unknown, EntityOpportunitiesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["entityOpportunities", id],
    entityOpportunitiesQuery,
    [{ id }],
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options, enabled: !!id && options?.enabled },
  );
  return { data: data?.entityOpportunites ?? [], ...rest };
}
