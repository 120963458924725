import { QueryClient } from "@tanstack/react-query";

import { EntityOpportunitiesQuery } from "../../../generated/app-service-gql/graphql";

export function updateCachedEntityOpportunities({
  queryClient,
  entityId,
  opportunityId,
  isAdding,
}: {
  queryClient: QueryClient;
  entityId: string;
  opportunityId: string;
  isAdding: boolean;
}) {
  queryClient.setQueryData<EntityOpportunitiesQuery>(
    ["entityOpportunities", entityId],
    (oldData) => {
      if (!oldData) {
        return isAdding ? { entityOpportunites: [opportunityId] } : { entityOpportunites: [] };
      }

      if (isAdding) {
        return { entityOpportunites: [...oldData.entityOpportunites, opportunityId] };
      }

      return {
        entityOpportunites: oldData.entityOpportunites.filter((id) => id !== opportunityId),
      };
    },
  );
}
