import React from "react";

import { SharedSaveProps } from "../types";
import { SaveToBuyerList } from "./SaveToBuyerList";
import { SaveToCompetitorOrPartner } from "./SaveToCompetitorOrPartner";
import { SaveToNoticeList } from "./SaveToNoticeList";

export function SaveToLists({
  entityType,
  entityId,
  creation,
  setCreation,
}: SharedSaveProps & {
  creation: boolean;
  setCreation: (creation: boolean) => void;
}) {
  switch (entityType) {
    case "notice":
      return <SaveToNoticeList entityId={entityId} creation={creation} setCreation={setCreation} />;
    case "buyer":
      return <SaveToBuyerList buyerId={entityId} creation={creation} setCreation={setCreation} />;
    case "supplier":
      return (
        <SaveToCompetitorOrPartner
          supplierGuid={entityId}
          creation={creation}
          setCreation={setCreation}
        />
      );
    default:
      return null;
  }
}
