import React, { useState } from "react";
import { App } from "antd5";

import { CreateBuyerListForm } from "components/buyers/CreateBuyerListForm";
import { GetBuyerListsPermissionEnum } from "lib/generated/app-api";
import { useAddBuyerEntries } from "lib/hooks/api/buyer_lists/useAddBuyerEntries";
import {
  AllBuyerLists,
  useBuyerLists,
  useIsBuyerInList,
} from "lib/hooks/api/buyer_lists/useBuyerLists";
import { useCreateBuyerList } from "lib/hooks/api/buyer_lists/useCreateBuyerList";
import { useRemoveBuyerEntries } from "lib/hooks/api/buyer_lists/useRemoveBuyerEntries";
import { routes } from "lib/routes";
import { ActionMessageContent } from "../ActionMessageContent";
import { CreateListFormProps, Item, SaveToList } from "./SaveToList";

export function SaveToBuyerList({
  buyerId,
  creation,
  setCreation,
}: {
  buyerId: string;
  creation: boolean;
  setCreation: (creation: boolean) => void;
}) {
  const { message } = App.useApp();

  const {
    data: allBuyerLists,
    isLoading,
    isError,
  } = useBuyerLists(GetBuyerListsPermissionEnum.CreateBuyerListEntries, {
    enabled: !!window.currentUser,
  });

  const [searchText, setSearchText] = useState("");

  const { mutate: addBuyerToList } = useAddBuyerEntries({
    onSuccess: (_, variables) =>
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`Buyer saved to ${variables.buyerListName}`}
            objectType="list"
            url={`${routes.buyerLists}/${variables.buyerListGuid}`}
          />
        ),
      }),
    onError: (_, variables) => message.error(`Failed to save buyer to ${variables.buyerListName}`),
  });

  const { mutate: removeBuyerFromList } = useRemoveBuyerEntries({
    onSuccess: (_, variables) =>
      message.open({
        icon: null,
        content: (
          <ActionMessageContent
            message={`Buyer removed from ${variables.buyerListName}`}
            objectType="list"
            url={`${routes.buyerLists}/${variables.buyerListGuid}`}
          />
        ),
      }),
    onError: (_, variables) =>
      message.error(`Failed to remove buyer from ${variables.buyerListName}`),
  });

  const {
    isSaved: { savedListNames: listsWithBuyer },
  } = useIsBuyerInList(buyerId);

  const allItems = mapToDisplayItems(allBuyerLists, listsWithBuyer);
  const filteredItems = filterBySearchText(searchText, allItems);

  const onItemClick = (id: string, isSaved: boolean) => {
    const clickedList = allBuyerLists?.all.find((list) => list.id === id);
    if (!clickedList) {
      return;
    }

    if (isSaved) {
      removeBuyerFromList({
        buyerListGuid: clickedList.id,
        buyerListName: clickedList.name,
        buyerGuids: [buyerId],
      });
    } else {
      addBuyerToList({
        buyerListGuid: clickedList.id,
        buyerListName: clickedList.name,
        buyerGuids: [buyerId],
      });
    }
  };

  return (
    <SaveToList
      items={filteredItems}
      isLoading={isLoading}
      isError={isError}
      onItemClick={onItemClick}
      entityId={buyerId}
      entityType="buyer"
      onSearch={(text) => setSearchText(text)}
      CreateListForm={CreateBuyerListFormWrapper}
      creation={creation}
      setCreation={setCreation}
    />
  );
}

function mapToDisplayItems(
  allBuyerLists: AllBuyerLists | undefined,
  listsWithBuyer: string[],
): Item[] {
  return (
    allBuyerLists?.all.map((buyerList) => ({
      id: buyerList.id,
      label: buyerList.name,
      isSaved: listsWithBuyer.some((listName) => listName === buyerList.name),
      viewUrl: `${routes.buyerLists}/${buyerList.id}`,
    })) ?? []
  );
}

function filterBySearchText(searchText: string, allListItems: Item[]) {
  return searchText
    ? allListItems.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))
    : allListItems;
}

function CreateBuyerListFormWrapper(props: CreateListFormProps) {
  const { message } = App.useApp();

  const { mutate: createList } = useCreateBuyerList({
    options: {
      onSuccess: (data) => {
        message.open({
          icon: null,
          content: (
            <ActionMessageContent
              message={`Buyer saved to ${data.name}`}
              objectType="list"
              url={`${routes.buyerLists}/${data.id}`}
            />
          ),
        });
        props.onSuccess();
      },
      onError: () =>
        message.error(
          "Unable to create your buyer list. Please contact customer support if the problem persists",
        ),
    },
    contextSource: "Save drawer",
  });

  return (
    <CreateBuyerListForm onSubmit={createList} entityIds={props.entityIds} footer={props.footer} />
  );
}
