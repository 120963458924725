import React from "react";
import styled from "@emotion/styled";
import { ErrorBoundary } from "@sentry/react";
import { Flex, Spacer, Text } from "styles/utility-components";

import Button from "components/ui/button/Button";
import EmptyState from "components/ui/empty_state/EmptyState";
import { useSupportChat } from "lib/utils/useSupportChat";

interface ErrorBoundaryFallbackProps {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
}

// Wrapped into a separate component so if this crashes, it won't take the rest of the fallback with it
const LivechatWrapper = () => {
  const { openSupportChat } = useSupportChat();
  return (
    <Button buttonType="tertiary" onClick={() => openSupportChat("Error Boundary")}>
      Chat with us
    </Button>
  );
};

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({
  error,
  componentStack,
  eventId,
}) => {
  return (
    <Wrapper column width="100%" justifyContent="safe center" alignItems="safe center" gap="16px">
      <EmptyState
        type="loadError"
        size="large"
        width="100%"
        heading="Oops! Something has gone wrong"
        description={
          <>
            We're sorry but an error has occured. Please try the following actions to resolve the
            issue.
            <Spacer vertical={8} />
            If the issue persists, contact support and provide the event ID: <code>{eventId}</code>
          </>
        }
        action={
          <Flex gap="8px">
            <Button buttonType="primary" onClick={() => window.location.reload()}>
              Refresh
            </Button>
            <Button buttonType="secondary" as="a" href="/">
              Go to home
            </Button>
            <ErrorBoundary
              fallback={
                <Button buttonType="tertiary" as="a" href="mailto:cs@stotles.com">
                  Email us
                </Button>
              }
            >
              <LivechatWrapper />
            </ErrorBoundary>
          </Flex>
        }
      />
      <details>
        <summary>Error Details</summary>
        <Flex column gap="8px">
          <Text description>
            Event ID: <code>{eventId}</code>
          </Text>
          <Text description>
            Error: <code>{error?.toString()}</code>
          </Text>
          <Text description>
            Component Stack: <code>{componentStack}</code>
          </Text>
        </Flex>
      </details>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)({
  maxWidth: "632px", // 600px + (16px * 2) padding
  margin: "0 auto",
  padding: "16px 16px 54px 16px", // Increased bottom to offset the Error Details so the rest is centered
  wordBreak: "break-word",
});
