import React from "react";

import BookmarkIcon from "components/actions/Bookmark";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { Opportunity } from "lib/generated/app-service-gql/graphql";
import { SaveSupplierButton } from "./lists/SaveSupplierButton";
import { SaveToButton } from "./SaveToButton";
import { SharedSaveProps } from "./types";

type DisplayType = "bookmark" | "button";

type InRowSaveToButtonProps = SharedSaveProps & {
  displayType: DisplayType;
  isSaved: boolean;
  listCount: number;
  entityName?: string; // Only used for suppliers currently
  opportunities?: Pick<Opportunity, "id">[];
};

export function InRowSaveToButton({
  displayType,
  isSaved,
  entityName,
  opportunities,
  ...rest
}: InRowSaveToButtonProps) {
  const button =
    rest.entityType === "supplier" ? (
      <SaveSupplierButton supplierGuid={rest.entityId} supplierName={entityName} />
    ) : (
      <SaveToButton {...rest} opportunities={opportunities} />
    );
  return (
    <div>
      {isSaved && displayType === "bookmark" && <BookmarkIcon colour="blue" filled />}
      {isSaved && displayType === "button" && button}
      {!isSaved && <span className={SHOW_ON_HOVER}>{button}</span>}
    </div>
  );
}
