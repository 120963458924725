import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { Layout } from "antd5";
import classnames from "classnames";

import { useVariableValue, VERTICAL_NAV } from "../../lib/featureFlags";
import AppLayoutNavbar from "./AppLayoutNavbar";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";
import SmallScreenWarning from "./SmallScreenWarning";

import css from "./AppContentLayout.module.scss";

type AppContentLayoutProps = {
  hideMenuItems?: boolean;
  hideUserItems?: boolean;
  hideNavBar?: boolean;
  displayCustomAction?: React.ReactNode;
  disableLogoNav?: boolean;
  textLogo?: boolean;
  contentClassName?: string;
  children: React.ReactNode;
};

function AppContentLayout({
  hideMenuItems,
  hideUserItems,
  hideNavBar,
  displayCustomAction,
  disableLogoNav,
  children,
  contentClassName,
  textLogo,
}: AppContentLayoutProps) {
  const isVerticalNavEnabled = useVariableValue(VERTICAL_NAV, false);

  const showNav = window.currentUser && !hideMenuItems;

  return (
    <div className={isVerticalNavEnabled && showNav ? css.verticalNavLayout : undefined}>
      <AppLayoutNavbar
        hideNavBar={hideNavBar}
        hideMenuItems={hideMenuItems}
        hideUserItems={hideUserItems}
        displayCustomAction={displayCustomAction}
        disableLogoNav={disableLogoNav}
        textLogo={textLogo}
      />
      <SmallScreenWarning />
      <Layout.Content className={classnames(css.pageContent, contentClassName)}>
        <ErrorBoundary showDialog fallback={(props) => <ErrorBoundaryFallback {...props} />}>
          {children}
        </ErrorBoundary>
      </Layout.Content>
    </div>
  );
}

export default AppContentLayout;
