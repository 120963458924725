import React from "react";
import classnames from "classnames";

import PaywallPopover from "components/paywall/PaywallPopover";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { NoticeDetailsResponse, ProviderTypes } from "lib/generated/integration-api";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useNoticeDetails } from "lib/hooks/api/integrations/useNoticeDetails";
import { useDialogManager } from "lib/providers/DialogManager";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { EventNames } from "lib/tracking";
import { EntityType } from "lib/types/graphQLEnums";
import { ButtonTypes, SendToCRMButton, ViewInCRMButton } from "./ProviderButtons";
import { SendNoticeToCRMModal } from "./SendNoticeToCRMModal";

type Notice = {
  guid: string;
  name: string;
  value?: number;
  url: string;
  closeDate: string;
  type?: EntityType;
};

type SendNoticeToCRMButtonProps = {
  notice: Notice;
  providerType: ProviderTypes;
  className?: string;
  noticeDetails?: NoticeDetailsResponse;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
};

export function SendNoticeToCRM({
  notice,
  containerClassName,
  displayInRow = false,
  type,
  fullWidth,
  align,
}: {
  notice: Notice;
  containerClassName?: string;
  displayInRow?: boolean;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
}): JSX.Element | null {
  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;
  const { data: noticeDetails } = useNoticeDetails(notice.guid, { enabled: !!providerType });

  return (
    <div
      className={classnames(containerClassName, {
        [SHOW_ON_HOVER]: !displayInRow || !noticeDetails?.externalUrl,
      })}
    >
      {providerType && (
        <SendNoticeToCRMButton
          type={type}
          notice={notice}
          providerType={providerType}
          noticeDetails={noticeDetails}
          fullWidth={fullWidth}
          align={align}
        />
      )}
    </div>
  );
}

function SendNoticeToCRMButton({
  notice,
  noticeDetails,
  providerType,
  type,
  fullWidth,
  align,
}: SendNoticeToCRMButtonProps) {
  const dialogManager = useDialogManager();

  const { checkSubscription, authorised: hasAwards } = useCheckSubscription("AWARDS", {
    "Context source": "CRM button",
  });

  if (notice.type === "Contract" && !hasAwards) {
    return (
      <PaywallPopover featureType="AWARDS" contextSource="CRM button">
        <SendToCRMButton
          providerType={providerType}
          onClick={(e) => {
            e?.stopPropagation();
            checkSubscription();
          }}
          eventName={EventNames.sendToCRMModalOpened}
          fullWidth={fullWidth}
          align={align}
          type={type}
        />
      </PaywallPopover>
    );
  }

  return noticeDetails?.externalUrl ? (
    <ViewInCRMButton
      externalUrl={noticeDetails.externalUrl}
      providerType={providerType}
      type={type}
      fullWidth={fullWidth}
      align={align}
    />
  ) : (
    <SendToCRMButton
      providerType={providerType}
      onClick={async () =>
        void dialogManager.openDialog(SendNoticeToCRMModal, {
          notice,
          providerType: providerType,
        })
      }
      loading={!noticeDetails}
      eventName={EventNames.sendToCRMModalOpened}
      type={type}
      fullWidth={fullWidth}
      align={align}
    />
  );
}
