import * as React from "react";
import { Empty } from "antd5";

import { DispatchComponentProps } from "components/reports/Section";
import { IframeSection as iIframeSection } from "lib/generated/admin-api";

export default function IframeSection({
  section,
  reportSectionId,
}: DispatchComponentProps<iIframeSection>): JSX.Element {
  return (
    <div id={reportSectionId}>
      {section.src ? (
        <iframe
          src={section.src}
          style={{
            backgroundColor: "transparent",
            border: "none",
            width: "100%",
            height: "100%",
            minHeight: 600,
          }}
          // TODO APP-2381 Add sandbox + credentialless attribute. Currently, some sources require "allow-same-origin"
          // with "allow-scripts" so sandbox is not being used since that combo allows escaping the iframe.
        />
      ) : (
        <Empty description="Paste an iframe or src to get started" />
      )}
    </div>
  );
}
