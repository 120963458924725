import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Input, Popover } from "antd5";
import { TooltipPlacement } from "antd5/es/tooltip";

import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useAddNoticeToList } from "lib/hooks/api/notices/lists/useAddNoticeToList";
import { useNoticeLists } from "lib/hooks/api/notices/lists/useNoticeLists";
import { useNoticeListsForRecord } from "lib/hooks/api/notices/lists/useNoticeListsForRecord";
import { useRemoveNoticeFromList } from "lib/hooks/api/notices/lists/useRemoveNoticeFromList";
import * as tracking from "lib/tracking";
import { BasicSignal, RecordListSummary } from "lib/types/models";
import { OPPORTUNTIES } from "../../lib/featureFlags";
import { OpportunityEntityType } from "../../lib/types/graphQLEnums";
import { SaveToButton } from "../opportunities/saving/SaveToButton";
import { AddNewListButton } from "./AddNewListButton";

import css from "./SaveRecord.module.scss";

type SavedRecords = {
  recordName: string | null;
  recordGuid: string;
  recordStage: string;
  procurementStageId: string;
  recordValue: number | null | undefined;
  buyerIds: string[];
  supplierIds: string[];
  recordCloseDate?: string | null;
  signals: BasicSignal[];
  placement?: TooltipPlacement;
  trigger: JSX.Element;
  isParentClosed?: boolean;
  contextSource: "In-table row" | "In-table dropdown" | "Title header" | "Record preview slide-out";
};

function SaveRecord({
  recordGuid,
  recordStage,
  signals,
  placement,
  trigger,
  isParentClosed,
  contextSource,
}: SavedRecords): JSX.Element {
  const [visible, setVisible] = useState(false);
  const { data } = useNoticeLists({ enabled: visible });
  const { data: recordListsForRecord } = useNoticeListsForRecord(recordGuid, { enabled: visible });
  const { mutate: addToList } = useAddNoticeToList();
  const { mutate: removeFromList } = useRemoveNoticeFromList();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (isParentClosed) {
      setVisible(false);
    }
  }, [isParentClosed]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onListButtonClick = async (userListId: number) => {
    const containsRecord = recordListsForRecord?.some((x) => x.id === userListId);
    const updateList = data?.allLists?.find((x) => x.id === userListId);

    if (updateList) {
      if (containsRecord) {
        removeFromList({
          recordGuid,
          list: updateList,
          recordStage: recordStage,
          signals: signals,
          contextSource,
        });
      } else {
        addToList({
          recordGuid,
          recordStage: recordStage,
          signals: signals,
          list: updateList,
          contextSource,
        });
      }
    }
  };

  const content = (
    <tracking.TrackingProvider data={{ "Context source": "Dropdown" }}>
      <div className={css.saveRecordMenu} onClick={(e) => e.stopPropagation()}>
        <div className={css.menuItem}>
          <Input
            type="text"
            className={css.searchList}
            value={searchValue}
            placeholder="Find list"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              handleSearchChange(e);
            }}
          />
        </div>
        <div className={css.scrollLists}>
          <div className={css.userListTitle}>Created by me</div>
          <UserList
            list={data?.userLists.filter((l) =>
              l.name.toLowerCase().includes(searchValue.toLowerCase()),
            )}
            onListButtonClick={onListButtonClick}
            recordListsForRecord={recordListsForRecord}
          />
          <AddNewListButton />
          <div className={css.userListTitle}>Created by others</div>
          <UserList
            list={data?.sharedLists.filter((l) =>
              l.name.toLowerCase().includes(searchValue.toLowerCase()),
            )}
            onListButtonClick={onListButtonClick}
            recordListsForRecord={recordListsForRecord}
          />
        </div>
      </div>
    </tracking.TrackingProvider>
  );

  return (
    <>
      <Popover
        placement={placement}
        content={content}
        className={css.popover}
        trigger="click"
        open={visible}
        onOpenChange={setVisible}
      >
        {trigger}
      </Popover>
    </>
  );
}

type UserListProp = {
  list?: GetRecordListsResponseResult[];
  onListButtonClick: (id: number) => void;
  recordListsForRecord?: RecordListSummary[];
};

function UserList({
  list,
  onListButtonClick,
  recordListsForRecord = [],
}: UserListProp): JSX.Element {
  return list && list.length > 0 ? (
    <ul className={css.userList}>
      {list.map((x) => (
        <UserListItem
          key={x.id}
          listItem={x}
          onListButtonClick={onListButtonClick}
          recordListsForRecord={recordListsForRecord}
        />
      ))}
    </ul>
  ) : (
    <span className={css.noList}>No lists available</span>
  );
}

type UserListItemProps = {
  listItem: GetRecordListsResponseResult;
  onListButtonClick: (id: number) => void;
  recordListsForRecord: RecordListSummary[];
};

function UserListItem({
  listItem,
  onListButtonClick,
  recordListsForRecord,
}: UserListItemProps): JSX.Element {
  const [mouseEntered, setMouseEntered] = React.useState(false);

  const containsRecord = recordListsForRecord.some((x) => x.id === listItem.id);

  return (
    <li
      key={listItem.id}
      className={css.userListItem}
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
      onClick={() => onListButtonClick(listItem.id)}
    >
      <span className={css.listName}>
        <b>{listItem.name}</b> ({listItem.recordCount})
      </span>
      <div className={css.actionContainer}>
        {mouseEntered ? (
          containsRecord ? (
            <Button danger ghost icon={<CloseOutlined />}>
              Remove
            </Button>
          ) : (
            <Button type="primary" ghost icon={<PlusOutlined />}>
              Save
            </Button>
          )
        ) : (
          containsRecord && (
            <Button type="primary" ghost className={css.savedButton} icon={<CheckOutlined />}>
              Saved
            </Button>
          )
        )}
      </div>
    </li>
  );
}

function SaveRecordWrapper(props: SavedRecords) {
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);
  const { data: notListsForNotice } = useNoticeListsForRecord(props.recordGuid, {
    enabled: isOpportunitiesEnabled,
  });

  if (isOpportunitiesEnabled) {
    return (
      <SaveToButton
        entityId={props.procurementStageId}
        entityListId={props.recordGuid}
        entityType="notice"
        listCount={notListsForNotice?.length}
        defaultOpportunityValues={{
          name: props.recordName ?? "",
          value: props.recordValue ?? undefined,
          assignedToId: window.currentUser?.guid,
          entities: [
            { entityId: props.procurementStageId, entityType: OpportunityEntityType.Notice },
            ...props.buyerIds.map((id) => ({
              entityId: id,
              entityType: OpportunityEntityType.Organisation,
            })),
            // dedupe supplier ids as there could be duplicates from lots
            ...[...new Set(props.supplierIds)].map((id) => ({
              entityId: id,
              entityType: OpportunityEntityType.Organisation,
            })),
          ],
        }}
      />
    );
  }

  return <SaveRecord {...props} />;
}

export default SaveRecordWrapper;
