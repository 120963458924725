import React, { useState } from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";

import { EventNames, useTracking } from "lib/tracking";
import { OPPORTUNTIES } from "../../../lib/featureFlags";
import { Opportunity } from "../../../lib/generated/app-service-gql/graphql";
import { useEntityOpportunities } from "../../../lib/hooks/api/opportunities/useEntityOpportunities";
import Button from "../../ui/button/Button";
import { SaveToDrawer } from "./SaveToDrawer";
import { SharedSaveProps } from "./types";

type Props = SharedSaveProps & {
  listCount?: number;
  compactDisplay?: boolean;
  opportunities?: Pick<Opportunity, "id">[];
};

export function SaveToButton({ compactDisplay = false, ...props }: Props) {
  const { logEvent } = useTracking();
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);
  const { data: entityOpportunities } = useEntityOpportunities(props.entityId, {
    enabled: isOpportunitiesEnabled && props.opportunities == null,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerClosing, setIsDrawerClosing] = useState(false);

  const listCount = props.listCount ?? 0;
  const opportunitiesCount = props.opportunities?.length ?? 0;
  const entityOpportunitiesCount = props.opportunities ? 0 : entityOpportunities?.length ?? 0;
  const totalSaved = opportunitiesCount + entityOpportunitiesCount + listCount;
  const isSaved = totalSaved > 0;

  if (!isOpportunitiesEnabled) {
    return null;
  }

  const handleDrawerClose = () => {
    setIsDrawerClosing(true);
    setTimeout(() => {
      setIsDrawerOpen(false);
      setIsDrawerClosing(false);
    }, 300); // timeout duration to match the drawer closing animation duration
  };

  return (
    <>
      <Button
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          setIsDrawerOpen(true);
          logEvent(EventNames.saveDataClicked, { "Data type": props.entityType.toUpperCase() });
        }}
        icon="bookmark"
        iconProps={{
          color: "sysTextWhite",
          fill: isSaved ? "sysTextWhite" : "transparent",
        }}
      >
        {!compactDisplay && (isSaved ? `Saved (${totalSaved})` : "Save")}
      </Button>
      {(isDrawerOpen || isDrawerClosing) && (
        <SaveToDrawer
          {...props}
          isOpen={isDrawerOpen && !isDrawerClosing}
          onClose={handleDrawerClose}
        />
      )}
    </>
  );
}
