import { QueryClient } from "@tanstack/react-query";

import { ContactsQuery } from "../../../generated/app-service-gql/graphql";
import { ContactResult } from "./useContacts";

export function updateCachedContacts(
  queryClient: QueryClient,
  contactId: string,
  updateContact: (contact: ContactResult) => ContactResult,
) {
  queryClient.setQueriesData<ContactsQuery>(["contacts"], (resp) => {
    if (!resp?.contacts.results) {
      return resp;
    }
    const results = [...resp.contacts.results];
    const contactIndex = results.findIndex((r) => r.id === contactId);

    if (contactIndex === -1) {
      return resp;
    }

    results[contactIndex] = updateContact(results[contactIndex]);

    return {
      contacts: { ...resp.contacts, results },
    };
  });
}

export function updateCachedContactOpportunities({
  queryClient,
  contactId,
  opportunityId,
  isAdding,
}: {
  queryClient: QueryClient;
  contactId: string;
  opportunityId: string;
  isAdding: boolean;
}) {
  updateCachedContacts(queryClient, contactId, (contact) => {
    const opportunities = contact.opportunities || [];

    if (isAdding) {
      if (!opportunities.find((opp) => opp.id === opportunityId)) {
        return {
          ...contact,
          opportunities: [...opportunities, { id: opportunityId }],
        };
      }
    }

    return {
      ...contact,
      opportunities: isAdding
        ? opportunities
        : opportunities.filter((opp) => opp.id !== opportunityId),
    };
  });
}
