import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { EventNames, useTracking } from "lib/tracking";
import { graphql } from "../../../generated/app-service-gql";
import {
  AddOpportunityEntityMutation,
  AddOpportunityEntityMutationVariables,
  OpportunityEntitiesInput_Entities_EntityTypeEnum_1,
} from "../../../generated/app-service-gql/graphql";
import { updateCachedContactOpportunities } from "../contacts/utils";
import { updateCachedNoticeOpportunities } from "../notices/utils";
import { useGraphQlMutation } from "../useGraphQlClient";
import { updateCachedEntityOpportunities } from "./utils";

const ADD_OPPORTUNITY_ENTITY_MUTATION = graphql(`
  mutation addOpportunityEntity($id: String!, $entites: OpportunityEntitiesInput!) {
    addOpportunityEntities(id: $id, entities: $entites) {
      id
    }
  }
`);

export function useAddOpportunityEntity(
  options?: UseMutationOptions<
    AddOpportunityEntityMutation,
    unknown,
    AddOpportunityEntityMutationVariables,
    unknown
  >,
) {
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();

  return useGraphQlMutation(ADD_OPPORTUNITY_ENTITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      for (const entity of variables.entites.entities) {
        // Update entity opportunities cache
        updateCachedEntityOpportunities({
          queryClient,
          entityId: entity.entityId,
          opportunityId: variables.id,
          isAdding: true,
        });

        // Update notice cache if entity is a notice
        if (entity.entityType === OpportunityEntitiesInput_Entities_EntityTypeEnum_1.Notice) {
          updateCachedNoticeOpportunities({
            queryClient,
            lookupId: entity.entityId,
            opportunityId: variables.id,
            isAdding: true,
          });
        }

        // Update contact cache if entity is a contact
        if (entity.entityType === OpportunityEntitiesInput_Entities_EntityTypeEnum_1.Contact) {
          updateCachedContactOpportunities({
            queryClient,
            contactId: entity.entityId,
            opportunityId: variables.id,
            isAdding: true,
          });
        }

        // TODO: Add more entity types as they are ported over to GraphQL
      }
      options?.onSuccess?.(data, variables, context);
      logEvent(EventNames.opportunityDataAdded);
    },
  });
}
