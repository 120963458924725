import React from "react";
import { FieldValues, useController } from "react-hook-form";

import { useTeams } from "../../lib/hooks/api/teams/useTeams";
import { Select, SelectProps } from "./Inputs";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

type Option = {
  label: string;
  value: string;
};

export default function TeamUserSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { field } = useController(props);

  const { data: teams, isLoading: loadingTeams } = useTeams();

  const options: Option[] =
    teams
      ?.flatMap((team) => team.users)
      .map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.guid,
      })) || [];

  return <Select {...props} options={options} defaultValue={field.value} loading={loadingTeams} />;
}
