import React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import { Skeleton } from "antd5/lib";
import { toast } from "sonner";

import { BasicContentBanner } from "components/banners/Banners";
import { NoticeDetails, useNotice } from "lib/hooks/api/notices/useNotice";
import { useNoticeHighlighting } from "lib/hooks/api/notices/useNoticeHighlighting";
import useElementInViewport from "lib/hooks/useElementInViewport";
import { RecordViewerEventData, useRecordViewer } from "lib/providers/RecordViewer";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { getSignalTypes } from "lib/types/models";
import NoticeDetailsContent from "./NoticeDetailsContent";
import NoticeHeader from "./NoticeHeader";

import css from "./NoticePreview.module.scss";

type NoticePreviewProps = {
  guid: string;
  eventData?: RecordViewerEventData;
};

type NoticePreviewContentProps = {
  notice: NoticeDetails;
  eventData?: RecordViewerEventData;
};

function NoticePreviewContainer({ guid, eventData }: NoticePreviewProps) {
  const subscription = useSubscription();
  const hasAwards = subscription.hasDataTypes("AWARDS");

  const { data: notice, isLoading } = useNotice(guid, {
    onSuccess: (data) => {
      if (data.notice.stage === "AWARDED" && !hasAwards) {
        toast.message("Free contract award unlocked", {
          icon: "🎉",
        });
      }
    },
  });

  if (isLoading) {
    return (
      <div className={css.loadingSkeleton}>
        <Skeleton active title paragraph={{ rows: 5, width: "100%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "80%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "60%" }} />
      </div>
    );
  }

  if (!notice) {
    return <div>No data found for this record.</div>;
  }

  return (
    <>
      <NoticePreview notice={notice} eventData={eventData} />
    </>
  );
}

function NoticePreview({ notice, eventData }: NoticePreviewContentProps) {
  const subscription = useSubscription();

  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const { closeRecord } = useRecordViewer();
  const { logEvent } = useTracking();

  const signalTypes = getSignalTypes(notice.signals ?? []);

  const cpvCodes = notice.cpvCodes.map((code) => `${code.code} - ${code.name}`);

  const signalScore = notice.score ?? 0;

  React.useEffect(() => {
    logEvent(EventNames.recordPreviewSlideoutOpened, {
      ...eventData,
      "Record guid": notice.guid,
      "Signal score": signalScore,
      Stage: notice.stage ?? "Unknown",
      "Signal types": signalTypes ? Object.keys(signalTypes) : [],
      "Signals.CPV codes": cpvCodes || [],
      Signals: signalTypes,
    });
    // Should log only once on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { highlightTerms, filters } = useNoticeHighlighting(notice.procurementStage.id);

  const hasAwards = subscription.hasDataTypes("AWARDS");

  return (
    <>
      <TrackingProvider
        data={{
          ...eventData,
          "Context source": `Record preview slide-out`,
          "Record GUID": notice.guid,
          "Signal score": signalScore,
          Stage: notice.stage ?? "Unknown",
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          Signals: signalTypes,
        }}
      >
        <div className={css.previewWrapper}>
          <div className={css.controls}>
            <Button
              title="Close"
              icon={<DoubleRightOutlined className={css.closeIcon} />}
              onClick={closeRecord}
              className={css.controlsBtn}
            />
          </div>
          <div className={css.previewContent}>
            {!hasAwards && notice.stage === "AWARDED" && <BasicContentBanner />}
            <NoticeHeader
              notice={notice}
              filters={filters}
              headerHighlights={highlightTerms?.title || []}
              trackingContextName="Record preview slide-out"
              disableTitleLink={!!window.guestUser}
              headerClassName={css.header}
              scrollClassName={css.scroll}
              isTopOfPageVisible={topOfPageIsVisible}
            />
            <div ref={topOfPageRef} />
            <div className={css.contentContainer}>
              <NoticeDetailsContent
                notice={notice}
                containerClassName={css.columnsContainer}
                trackingContextName="Record preview slide-out"
                noticeHighlights={highlightTerms}
              />
            </div>
          </div>
        </div>
      </TrackingProvider>
    </>
  );
}

export default NoticePreviewContainer;
