import React, { ReactNode, useState } from "react";
import styled from "@emotion/styled";

import PaywallStar from "../../../lib/icons/PaywallStar";
import { chevronRight } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { usePaywall } from "../../../lib/providers/Paywall";
import { fadeIn } from "../../../styles/animations";
import { Text } from "../../../styles/utility-components";
import { FeatureType } from "../../paywall/paywallUtils";

type NavItemProps = {
  title: string;
  icon?: JSX.Element;
  expanded?: boolean;
  path?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
  children?: ReactNode;
  isSubNavExpanded?: boolean;
  paywall?: FeatureType;
  badge?: string | number;
  shouldAnimate?: boolean;
  unreadNotifications?: number;
};

function NavItem({
  title,
  icon,
  expanded = true,
  path,
  onClick,
  children,
  isSubNavExpanded = false,
  paywall,
  unreadNotifications,
  shouldAnimate = false,
}: NavItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const active = window.location.pathname === path || (!expanded && isSubNavExpanded);
  const { openPaywallModal } = usePaywall();

  return (
    <Wrapper>
      <ItemWrapper
        as={path ? "a" : "div"}
        onClick={paywall ? () => openPaywallModal(paywall) : onClick}
        href={paywall ? undefined : path}
        active={!!active}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {icon && (
          <IconWrapper>
            {unreadNotifications && <RedDot />}
            <Icon svg={icon} size={16} color={"inherit"} hide={isHovered && !!children} />
            {children && (
              <ChevronIcon
                svg={chevronRight}
                size={16}
                hide={!isHovered}
                isSubNavExpanded={isSubNavExpanded && expanded}
              />
            )}
          </IconWrapper>
        )}
        {expanded && (
          <StyledText
            removeLineHeight
            bold
            fontWeight="500"
            color="inherit"
            shouldAnimate={shouldAnimate}
          >
            {title}
          </StyledText>
        )}
        {paywall && expanded && <PaywallStar />}
        {unreadNotifications && expanded && <Badge>{unreadNotifications}</Badge>}
      </ItemWrapper>
      {children}
    </Wrapper>
  );
}

export default NavItem;

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

const ItemWrapper = styled.a<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  flexWrap: "nowrap",
  gap: 8,
  height: 32,
  alignItems: "center",
  padding: 8,
  borderRadius: 6,
  color: theme.colors.sysTextDefault,
  transition: "all 0.3s",
  userSelect: "none",

  "&:hover": {
    cursor: "pointer",
    color: theme.colors.white,

    ...(!active && {
      backgroundColor: theme.colors.sysPrimarySubtle,
      color: theme.colors.sysPrimaryHover,
    }),
  },

  ...(active && {
    backgroundColor: theme.colors.sysPrimaryDefault,
    color: theme.colors.white,
  }),
}));

const StyledText = styled(Text)<{ shouldAnimate: boolean }>(({ shouldAnimate }) => ({
  ...(shouldAnimate && {
    opacity: 0,
    animation: `${fadeIn} 0.3s forwards`,
  }),
}));

const IconWrapper = styled.div({
  position: "relative",
  display: "flex",
  alignItems: "center",
});

const Icon = styled(UIcon)<{ hide: boolean }>(({ hide }) => ({
  transition: "opacity 0.4s",
  opacity: hide ? 0 : 1,
  transitionDelay: hide ? "0s" : "0.15s", // stop icons overlapping
}));

const ChevronIcon = styled(UIcon)<{ hide: boolean; isSubNavExpanded: boolean }>(
  ({ hide, isSubNavExpanded }) => ({
    position: "absolute",
    opacity: hide ? 0 : 1,
    transition: `opacity 0.4s ease ${hide ? "0s" : "0.15s"}, transform 0.3s`,
    transform: isSubNavExpanded ? "rotate(90deg)" : "rotate(0deg)",
  }),
);

const Badge = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  width: 24,
  borderRadius: 4,
  backgroundColor: theme.colors.sysPrimarySubtle,
  color: theme.colors.sysPrimaryDefault,
  fontSize: 12,
  fontWeight: 500,
  marginLeft: "auto",
}));

const RedDot = styled.div(({ theme }) => ({
  position: "absolute",
  top: -2,
  right: 1,
  height: 8,
  width: 8,
  border: `1px solid ${theme.colors.sysBackgroundDefault}`,
  borderRadius: "50%",
  backgroundColor: theme.colors.sysDestructiveDefault,
  animation: `${fadeIn} 1s forwards`,
}));
