import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, Ref } from "react";
import styled from "@emotion/styled";

import UIcon, { IconProps } from "../../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../../styles/utility-components";

type ButtonType = "primary" | "secondary" | "tertiary";
type ButtonStyle = "default" | "destructive" | "inverse";
type ButtonElementTypes = "button" | "a" | undefined; // undefined = Button

type ButtonProps<T extends ButtonElementTypes> = {
  as?: T;
  children?: React.ReactNode;
  icon?: IconProps["icon"];
  iconProps?: Omit<IconProps, "icon">;
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
  blackText?: boolean;
  fullWidth?: boolean;
  justifyContent?: string;
} & (T extends "a"
  ? AnchorHTMLAttributes<HTMLAnchorElement> & { href: string }
  : ButtonHTMLAttributes<HTMLButtonElement>);

const ButtonInternal = <T extends ButtonElementTypes = "button">(
  {
    as,
    children,
    icon,
    iconProps,
    buttonType = "primary",
    buttonStyle = "default",
    blackText = false,
    ...rest
  }: ButtonProps<T>,
  ref: Ref<T extends "a" ? HTMLAnchorElement : HTMLButtonElement>,
) => {
  return (
    <ButtonWrapper
      as={as}
      buttonType={buttonType}
      buttonStyle={buttonStyle}
      buttonOnly={!children}
      {...(rest as object)}
      ref={ref as Ref<HTMLButtonElement>} // Not true but ref doesn't seem to change with as prop so forcing the type
    >
      {icon && <UIcon icon={icon} {...iconProps} size={16} className="icon" />}
      {typeof children === "string" ? (
        <Text color={blackText ? "sysTextDefault" : "inherit"} ellipsis>
          {children}
        </Text>
      ) : (
        children
      )}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button<{
  buttonType: ButtonType;
  buttonStyle: ButtonStyle;
  buttonOnly: boolean;
  fullWidth?: boolean;
  justifyContent?: string;
}>(({ theme, buttonType, buttonStyle, buttonOnly, fullWidth, justifyContent }) => ({
  all: "unset",
  height: 32,
  padding: buttonOnly ? "0 8px" : "0 12px",
  borderRadius: 4,
  transition: "background-color 0.2s, border-color 0.2s",
  display: "flex",
  flexGrow: fullWidth ? 1 : 0,
  alignItems: "center",
  justifyContent: justifyContent ?? "center",
  gap: 8,
  cursor: "pointer",

  ...(buttonType === "primary" && {
    backgroundColor: theme.colors.sysPrimaryDefault,
    color: theme.colors.sysTextWhite,
    border: "1px solid transparent", // Have so buttons all are the same height

    "&:hover": {
      color: theme.colors.sysTextWhite,
      backgroundColor: theme.colors.sysPrimaryHover,
    },
    "&:active": {
      backgroundColor: theme.colors.sysPrimaryPressed,
    },
    "&:focus-visible": {
      outline: `2px solid ${theme.colors.sysPrimarySubtleAlt}`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      backgroundColor: theme.colors.sysPrimarySubtleAlt,
    },

    ...(buttonStyle === "destructive" && {
      backgroundColor: theme.colors.sysDestructiveDefault,
      "&:hover": {
        color: theme.colors.sysTextWhite,
        backgroundColor: theme.colors.sysDestructiveHover,
      },
      "&:active": {
        backgroundColor: theme.colors.sysDestructivePressed,
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysDestructiveSubtleAlt}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: theme.colors.sysDestructiveSubtleAlt,
      },
    }),

    ...(buttonStyle === "inverse" && {
      backgroundColor: theme.colors.sysBackgroundDefault,
      color: theme.colors.sysTextDefault,
      "&:hover": {
        color: theme.colors.sysTextDefault,
        backgroundColor: "#F7F7F8",
      },
      "&:active": {
        backgroundColor: "#EEEFF1",
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysPrimarySubtleAlt}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: theme.colors.sysTextInactive,
      },
    }),
  }),

  ...(buttonType === "secondary" && {
    backgroundColor: theme.colors.sysBackgroundDefault,
    color: theme.colors.sysTextDefault,
    border: `1px solid ${theme.colors.sysBorderPrimary}`,

    "&:hover": {
      color: theme.colors.sysTextDefault,
      backgroundColor: theme.colors.sysPrimarySubtle,
      borderColor: theme.colors.sysPrimaryHover,
    },
    "&:active": {
      backgroundColor: theme.colors.sysPrimarySubtleAlt,
      borderColor: theme.colors.sysPrimaryPressed,
    },
    "&:focus-visible": {
      outline: `2px solid ${theme.colors.sysBorderPrimary}`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      backgroundColor: "#EEEFF1",
      color: theme.colors.sysTextInactive,
      "& .icon": {
        color: theme.colors.sysTextInactive,
      },
    },

    ...(buttonStyle === "destructive" && {
      borderColor: theme.colors.sysDestructiveDefault,

      "&:hover": {
        color: theme.colors.sysTextDefault,
        borderColor: theme.colors.sysDestructiveHover,
        backgroundColor: theme.colors.sysDestructiveSubtle,
        "& .icon": {
          color: theme.colors.sysDestructiveHover,
        },
      },
      "&:active": {
        borderColor: theme.colors.sysDestructivePressed,
        backgroundColor: theme.colors.sysDestructiveSubtleAlt,
        "& .icon": {
          color: theme.colors.sysDestructivePressed,
        },
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysDestructiveSubtleAlt}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: "#EEEFF1",
        borderColor: theme.colors.sysBorderPrimary,
        color: theme.colors.sysTextInactive,
        "& .icon": {
          color: theme.colors.sysTextInactive,
        },
      },
    }),

    ...(buttonStyle === "inverse" && {
      borderColor: theme.colors.sysBorderPrimary,
      backgroundColor: "transparent",
      color: theme.colors.sysTextWhite,

      "&:hover": {
        color: theme.colors.sysTextWhite,
        borderColor: theme.colors.sysTextWhite,
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
      "&:active": {
        borderColor: theme.colors.sysTextWhite,
        backgroundColor: "rgba(255, 255, 255, 0.12)",
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysPrimarySubtle}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        borderColor: theme.colors.sysTextInactive,
        color: theme.colors.sysTextInactive,
        "& .icon": {
          color: theme.colors.sysTextInactive,
        },
      },
    }),
  }),

  ...(buttonType === "tertiary" && {
    backgroundColor: theme.colors.sysBackgroundDefault,
    color: theme.colors.sysPrimaryDefault,
    border: "1px solid transparent", // Have so buttons all are the same height

    "&:hover": {
      color: theme.colors.sysPrimaryDefault,
      backgroundColor: theme.colors.sysPrimarySubtle,
    },
    "&:active": {
      backgroundColor: theme.colors.sysPrimarySubtleAlt,
    },
    "&:focus-visible": {
      outline: `2px solid ${theme.colors.sysPrimarySubtle}`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      color: theme.colors.sysTextInactive,
    },

    ...(buttonStyle === "destructive" && {
      color: theme.colors.sysDestructiveDefault,
      "&:hover": {
        color: theme.colors.sysDestructiveDefault,
        backgroundColor: theme.colors.sysDestructiveSubtle,
      },
      "&:active": {
        backgroundColor: theme.colors.sysDestructiveSubtleAlt,
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysDestructiveSubtleAlt}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        color: theme.colors.sysTextInactive,
      },
    }),

    ...(buttonStyle === "inverse" && {
      backgroundColor: "transparent",
      color: theme.colors.sysTextWhite,
      "&:hover": {
        color: theme.colors.sysTextWhite,
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(255, 255, 255, 0.12)",
      },
      "&:focus-visible": {
        outline: `2px solid ${theme.colors.sysPrimarySubtle}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        color: theme.colors.sysTextInactive,
      },
    }),
  }),
}));

ButtonInternal.displayName = ButtonWrapper.displayName;

/**
 *
 * @param as Which element to render this button as (button, a)
 * @param children Text or JSX to render inside the button
 * @param icon Untitled UI icon name to render inside the button
 * @param iconColorOverride A custom color to override the default color of the icon
 * @param buttonType The type of button to render (primary, secondary, tertiary)
 * @param buttonStyle The style of button to render (default, destructive, inverse)
 * @param blackText Whether the text should be forced to black
 */
const Button = forwardRef(ButtonInternal) as typeof ButtonInternal;

export default Button;
